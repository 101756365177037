// Typography
//
// Font, line-height, and color for body text, headings, and more.

// https://www.google.com/design/spec/style/typography.html#typography-styles
// http://www.getmdl.io/styles/index.html

$font-family-sans-serif: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$font-family-serif:      'Roboto Slab', 'Times New Roman', serif !default;

$h1-font-size:                 3.3125rem;
$h2-font-size:                 2.25rem !default;
$h3-font-size:                 1.5625rem;
$h4-font-size:                 1.125rem !default;
$h5-font-size:                 1.0625rem !default;
$h6-font-size:                 0.75rem !default;
$font-paragraph:               14px !default;
$font-size-navbar:             16px !default;
$font-size-small:              12px !default;


//
$display1-size: 7rem !default; // md display-4 112px was 6rem;
$display2-size: 3.5rem !default; // md display-3 56px was 5.5rem
$display3-size: 2.8125rem !default; // md display-2 45px was 4.5rem
$display4-size: 2.125rem !default; // md display-1 34px was 3.5rem

$headings-margin-bottom:     ($spacer / 2) !default;
//$headings-font-family:       inherit !default;
$headings-font-weight:         400 !default; // was 500

$font-weight-light: 300 !default;
$font-weight-default: 400 !default;
$font-weight-bold:    500 !default;
$font-weight-extra-bold: 700 !default;
