// This is a set of flex-based layout containers necessary for components such as the drawer.
//  It allows for flex based positioning such as sticky header and footer inside an bmd-layout, with a
//  bmd-layout-content that scrolls.

// mobile webkit smooth scrolling: http://stackoverflow.com/a/15147497/2363935

// First element
.bmd-layout-canvas {
  // added by js?
  position: absolute;
  width: 100%;
  height: 100%;
}

// Primary layout container, belongs inside a bmd-layout-canvas.
.bmd-layout-container {
  position: relative;
  display: flex;
  flex-direction: column; // allows for sticky header and footer
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; // mobile webkit smooth scrolling
}

// Header layout (fixed top), belongs in a bmd-layout-container
.bmd-layout-header {
  z-index: 3;
  display: flex;
  flex-direction: column; // since this is a column, it is necessary that something like an actual navbar is a child
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 100%;
  max-height: 1000px;
  transform: translateZ(0); // mobile webkit smooth scrolling
  @include material-animation-default();
}

// Content layout, belongs in a bmd-layout-container
.bmd-layout-content {
  position: relative;
  z-index: 1; // under a header
  display: inline-block;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto; // mobile webkit smooth scrolling may need scroll, but auto seems to be working and omits the scroll border
  -webkit-overflow-scrolling: touch; // mobile webkit smooth scrolling
  @include material-animation-default();
}

// Pushes flex-based content to the other end (x or y)
.bmd-layout-spacer {
  flex-grow: 1;
}

// Backdrop - generic backdrop to be used by other components i.e. drawer in overlay mode
.bmd-layout-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: transparent;
  transition-property: background-color;
  @include material-animation-default();

  @supports (pointer-events: auto) {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-property: opacity;
    visibility: visible;
    pointer-events: none;
  }

  // NOTE: the &.in style is responsive, generated by @mixin bmd-layout-backdrop-in() where needed.
}
