[class*="blogs-"] {
    padding: 50px 0;
}


.blogs-1 {
    .card {
        margin-bottom: 80px;
    }
}

.blogs-4 {
    .card {
        margin-bottom: 60px;
        text-align: center;
    }
}

.card-blog {
    .row {
        .card-category{
            margin-bottom: 0;
        }
        .card-description{
            line-height: 1.313rem;
        }
    }
}
