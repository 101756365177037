// Navs https://www.google.com/design/spec/components/tabs.html#tabs-specs

$nav-disabled-link-color: $gray-light !default;
$nav-disabled-link-hover-color: $gray-light !default;

$bmd-navbar-link-font-weight: $bmd-font-weight-base !default; //
$bmd-navbar-link-font-size: .875rem !default; // 14
$bmd-navbar-link-padding: .5321rem; // 7

// tabs & pills
$bmd-nav-tabs-pills-font-weight: 500 !default; //
$bmd-nav-tabs-pills-font-size: .875rem !default; // 14
$bmd-nav-tabs-pills-link-padding: 1.4286em .8575em !default; // spec // was .5em 1em   // relative em based on 14

// tabs only
$bmd-nav-tabs-border-size: .214rem !default; // 3px

$bmd-nav-tabs-color: $gray !default;
$bmd-nav-tabs-active-color: $gray-dark !default;
$bmd-nav-tabs-active-border-color: $primary !default;
$bmd-nav-tabs-disabled-link-color: $nav-disabled-link-color !default;
$bmd-nav-tabs-disabled-link-color-hover: $nav-disabled-link-hover-color !default;

$bmd-nav-tabs-primary-color: $bmd-inverse !default;
$bmd-nav-tabs-primary-active-color: $white !default;
$bmd-nav-tabs-primary-active-border-color: $white !default;
$bmd-nav-tabs-primary-disabled-link-color: $bmd-inverse-light !default;
$bmd-nav-tabs-primary-disabled-link-color-hover: $bmd-inverse-light !default;

$bmd-nav-tabs-inverse-color: $bmd-inverse !default;
$bmd-nav-tabs-inverse-active-color: $white !default;
$bmd-nav-tabs-inverse-active-border-color: $white !default;
$bmd-nav-tabs-inverse-disabled-link-color: $bmd-inverse-light !default;
$bmd-nav-tabs-inverse-disabled-link-color-hover: $bmd-inverse-light !default;

$nav-tabs-active-link-hover-bg: transparent !default; // $body-bg
